var $ = require("jquery");
import 'jquery-ui/ui/widgets/accordion';
import 'jquery-ui/ui/widgets/button';

$(document).ready(function () {
    $(function () {
        var icons = {
            // header: "ui-icon-circle-arrow-e",
            // activeHeader: "ui-icon-circle-arrow-s"
            header: "closed",
            activeHeader: "opened"
        };
        $(".faqs-accordion .accordion-wrapper").accordion({
            icons: icons,
            active: false,
            collapsible: true,
            header: 'div.accordion-header'
        });
        $("#toggle").button().on("click", function () {
            if ($("#accordion").accordion("option", "icons")) {
                $("#accordion").accordion("option", "icons", null);
            } else {
                $("#accordion").accordion("option", "icons", icons);
            }
        });
    });
});