var $ = require("jquery");

$(document).ready(function () {
  function setPadding(){
    let separatorhHeight = $(".pictures-with-text .separator img").height();
    let paddingbottom = separatorhHeight / 2 + 'px';
    $(".pictures-with-text .desktop").css('padding-bottom',paddingbottom );
  }
  $( window ).resize(function() {
    setPadding();
  });
  setPadding();
});
