import '../scss/main.scss';

$(document).ready(function () {

  if (document.getElementById("flaschecontainer")) {

    $(function () {
      paperExplosion();
    });

    function paperExplosion() {
      document.documentElement.addEventListener('mousewheel', function (e) {
        e.preventDefault()
      }, false);
      const flaschecontainer = $("#flaschecontainer");
      const elemTopDistance = flaschecontainer.offset().top;
      const flaschecontainerHeight = flaschecontainer.height() / 3 * 2;
      if ($(window).scrollTop() + flaschecontainerHeight > elemTopDistance) {
        flaschecontainer.addClass('active');
      }
      $(window).scroll(function (e) {
        if ($(window).scrollTop() + flaschecontainerHeight > elemTopDistance) {
          flaschecontainer.addClass('active');
        } else {
          //  flaschecontainer.removeClass('active');
        }
      });
    }
  }
});
