import Swiper, {Pagination, Navigation, Autoplay, EffectFade} from 'swiper';

var $ = require("jquery");

Swiper.use([Pagination, Navigation, Autoplay, EffectFade]);
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

// Wait for the window to load
window.onload = function() {
  const headerSwiper = new Swiper('#header .swiper', {
    loop: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: true,
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    speed: 300,
    fadeEffect: {crossFade: false},
    effect: "fade",
    // Add the following `on` property to the Swiper instance:
    on: {
      slideChangeTransitionStart: function() {
        // Double the delay for the first slide, reset for the rest
        if (this.realIndex === 0) {
          this.params.autoplay.delay = 14000;
        } else {
          this.params.autoplay.delay = 7000;
        }
      }
    }
  });
};





var testimonialSwiper = new Swiper('#testimonial .swiper', {
    loop: true,
    autoplay: {
        delay: 10000,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

$('#testimonial .swiper').on('mouseenter', function(e){
    testimonialSwiper.autoplay.stop();
})

$('#testimonial .swiper').on('mouseleave', function(e){
    testimonialSwiper.autoplay.start();
})


// if ($("header .swiper").length === 1) {
//     $('header .swiper-wrapper').addClass("disabled");
//     $('header .swiper-pagination').addClass("disabled");
// }
