var $ = require("jquery");

$(document).ready(function () {

  var mapDiv = $("#mapDiv");
  var overlay = $("#overlay");
  var sticker = $("#sticker");
  var mapButton = $("#mapButton");

  var button = $("#giftbox_button");
  var tomapButton = $('.to_map_button');

  if ($(window).width() < 1200) mapDiv.css("width", "100%");

  function open() {
    overlay.css("opacity", 1);
    overlay.css("z-index", 190);
   sticker.css("opacity", 0);
   sticker.css("z-index", -1);
    if ($(window).width() > 1200) mapDiv.css("left", "40%");
    else mapDiv.css("left", 0);
  }

  function close() {
    mapDiv.css("left", "100%");
    overlay.css("opacity", 0);
    overlay.css("z-index", -1);
   sticker.css("opacity", 1);
   sticker.css("z-index", 200);
  }

  sticker.click(function () {
    open();
  });
  mapButton.click(function () {
    open();
  });

  button.click(function () {
    open();
  });
  tomapButton.click(function () {
    open();
  });
  $("#closeMap").click(function () {
    close();
  });
  document.addEventListener('click', function(event){
    if(event.target.matches('#overlay')){
      close();
    }
  })
});
