var $ = require("jquery");

$(document).ready(function () {

    var width = $(".news-id .w-md-30percent").width();
    var lines = $(".news-id .line");

    lines.css("width", width)

    $( window ).resize(function() {
        width = $(".news-id .w-md-30percent").width();
        lines.css("width", width)
    });
});